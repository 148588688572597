import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import Script from 'next/script'
import {useEffectOnce} from 'react-use'
import TagManager from 'react-gtm-module'

import {CheckoutLine, handleRestResponse, restApi} from '@festi/common/api/rest'
import {ChatBubble} from '@festi/common/components/common'
import settings from '@festi/common/constants/settings'
import {useAuth, useCheckout} from '@festi/common/contexts'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BambuserOneToOneEmbed: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBambuserOneToOneReady: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onLoadZendesk: any
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Factory = any
type ProductFactory = Factory
type VariationFactory = Factory
type DetailFactory = Factory
type PriceFactory = Factory

export interface OneToOneEmbed {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (event: string, callback: (...args: any[]) => void) => void
  floatAbove: (element: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateProduct: (product: string, cb: (arg: ProductFactory) => any) => void
  show: () => void
}

interface CallbackParam<T1, T2 = void> {
  (param1: T1): T2
}

interface BambuserProviderProps {
  children: React.ReactNode
  disableVideo?: boolean
}

export const BambuserContext = createContext<Partial<BambuserContextProps>>({})

export function BambuserProvider({
  children,
  disableVideo,
}: BambuserProviderProps) {
  const {user, setPromptAuth} = useAuth()
  const {restCheckout, cartUpdate} = useCheckout()

  const userName = user?.name
  const bambuserId = settings.bambuserId

  const [openModal, setOpenModal] = useState(false)
  const [loadBambuser, setLoadBambuser] = useState(false)
  const [openOnLogin, setOpenOnLogin] = useState(false)
  const [oneToOneEmbed, setOneToOneEmbed] = useState<OneToOneEmbed>()

  const addToCart = useCallback(
    async (
      sku: string,
      quantity: number,
      callback: CallbackParam<{success: boolean; reason: string}>,
    ) => {
      const variant = await handleRestResponse(
        restApi.productVariantsRetrieve(sku),
        true,
      )

      if (variant) {
        cartUpdate({[variant.id]: quantity})
          .then(() =>
            callback({
              success: true,
              reason: '',
            }),
          )
          .catch((error) => {
            if (error.message === 'yourOutOfStockErrorMessage') {
              // Unsuccessful due to 'out of stock'!
              callback({
                success: false,
                reason: 'out-of-stock',
              })
            } else {
              // Unsuccessful due to other problems
              callback({
                success: false,
                reason: error.message,
              })
            }
          })
      }
    },
    [cartUpdate],
  )

  const cartIntegration = useCallback(
    (oneToOneEmbed: OneToOneEmbed) => {
      oneToOneEmbed?.on('should-add-item-to-cart', (addedItem, callback) => {
        const qtyInCart =
          restCheckout?.lines.find(
            (line: CheckoutLine) => line.variant.sku === addedItem.sku,
          )?.quantity || 0
        addedItem.quantity = qtyInCart + 1

        addToCart(addedItem.sku, addedItem.quantity, callback)
      })
      oneToOneEmbed?.on(
        'should-update-item-in-cart',
        (updatedItem, callback) => {
          addToCart(updatedItem.sku, updatedItem.quantity, callback)
        },
      )
      oneToOneEmbed?.on('goto-checkout', (_event) => {
        // Open checkout url in the same tab (enabled miniplayer)
        oneToOneEmbed?.floatAbove(window.location.origin + '/karfa')

        // Open checkout url in new tab and allow call to continue in current tab
        // window.open(window.location.origin + '/karfa', '_blank');
      })
    },
    [restCheckout, addToCart],
  )

  const provideProductData = useCallback((oneToOneEmbed: OneToOneEmbed) => {
    const transFormUrlToSku = (url: string) => {
      const urlParts = url.split('/')
      const sku = urlParts[urlParts.length - 1]
      return sku
    }

    oneToOneEmbed?.on('provide-product-data', (event) => {
      event.products.forEach(
        async ({
          ref,
          type,
          id: bambuserId,
        }: {
          ref: string
          type: string
          id: string
        }) => {
          // Mandatory to handle at least URL and SKU
          // Use the references provided above to fetch your product object
          // Provide product data to the widget using the methods below.
          // type: "url" | "product-reference" | "scanned-code"
          let sku = ''
          if (type === 'url') {
            sku = transFormUrlToSku(ref)
          } else if (type === 'product-reference') {
            sku = ref
          }

          // Fetch your product
          const yourProduct = await handleRestResponse(
            restApi.productVariantsRetrieve(sku),
            true,
          )

          if (yourProduct) {
            oneToOneEmbed.updateProduct(
              bambuserId,
              (productFactory: ProductFactory) => {
                return (
                  productFactory

                    // Mandatory
                    // currency of the product
                    .currency('ISK')

                    // Mandatory
                    // locale that product is localized to
                    // Bambuser follow  ISO 639-1 Code + "-" + ISO 3166-2 as standard locale format.
                    // The value should be of type string
                    .locale('en-US')

                    // Mandatory
                    // The product method contains a new chain
                    // these are methods specific to this actual product
                    .product((detailFactory: DetailFactory) =>
                      detailFactory

                        // Optional
                        // Localized name of the product
                        .name(yourProduct.name)

                        // Optional
                        // Sku of your product
                        .sku(yourProduct.sku)

                        // Optional
                        // Define the index of specific variation
                        // which will be selected by default when
                        // agent fetches a product

                        // Example:
                        //.defaultVariationIndex(yourMethodToGetVariationIndex(ref, type))
                        .defaultVariationIndex(0)

                        // Mandatory
                        // localized description for the product
                        .description(yourProduct.product.shortDescription)

                        // Optional
                        // URL for product
                        .url(
                          window.location.origin +
                            '/vorur/' +
                            yourProduct.product.slug +
                            '/' +
                            yourProduct.sku,
                        )

                        // Mandatory
                        // The attributes method contains a new chain
                        // returned array defines any dimensions (e.g. color, size, ...) of your product
                        .attributes((_attribute: unknown) => {
                          return []
                        })

                        // Mandatory
                        // The variations method contains a new chain
                        // Returned array defines variants (e.g. colors) of your product
                        .variations(
                          (variationFactory: () => VariationFactory) => {
                            return [
                              variationFactory()
                                .name(yourProduct.name)
                                .sku(yourProduct.sku)
                                .inStock(
                                  yourProduct.inventoryStatus.web !==
                                    'sold_out',
                                )
                                .imageUrls(
                                  yourProduct.images.map(
                                    (image) => image.image.productGallery2x,
                                  ),
                                )
                                .price((priceFactory: PriceFactory) =>
                                  priceFactory

                                    // Optional
                                    // original price (in case current is a sale price)
                                    .original(
                                      yourProduct.listings[settings.channel]
                                        .price.price,
                                    )

                                    // Mandatory
                                    // current price of the product
                                    .current(
                                      yourProduct.listings[settings.channel]
                                        .price.lowestPrice,
                                    ),
                                ),
                            ]
                          },
                        ),
                    )
                )
              },
            )
          }
        },
      )
    })
  }, [])

  const sendTagManager = useCallback((event: string, label: string) => {
    TagManager.dataLayer({
      dataLayer: {
        category: 'communication',
        event,
        event_label: label,
      },
      dataLayerName: 'dataLayer',
    })
  }, [])

  const onLoadZendesk = useCallback(() => {
    window.zE('webWidget', 'hide')
    window.zE('webWidget', 'setLocale', 'is')
  }, [])

  const updateOneToOne = useCallback(() => {
    setOneToOneEmbed(
      new window.BambuserOneToOneEmbed({
        orgId: bambuserId,
        queue: settings.bambuserQueue,
        data: {
          name: userName,
        },
      }),
    )
  }, [userName, bambuserId])

  const handleOpenVideoCall = useCallback(() => {
    // TODO: Change back or remove in 1-2 weeks.
    if (false && !userName) {
      setOpenOnLogin(true)
      setPromptAuth(true)
    } else {
      setOpenOnLogin(false)
      oneToOneEmbed && oneToOneEmbed.show()
    }
  }, [userName, oneToOneEmbed, setPromptAuth, setOpenOnLogin])

  const handleLoadBambuser = () => setLoadBambuser(true)

  useEffect(() => {
    if (userName && openOnLogin) {
      handleOpenVideoCall()
    }
  }, [userName, openOnLogin, handleOpenVideoCall])

  useEffectOnce(() => {
    window.onLoadZendesk = () => {
      setLoadBambuser(true)
      setOpenModal(true)
      sendTagManager('open info card', 'main bubble')
    }
  })

  return (
    <BambuserContext.Provider
      value={{
        oneToOneEmbed,
        updateOneToOne,
        cartIntegration,
        provideProductData,
        handleLoadBambuser,
        handleOpenVideoCall,
      }}
    >
      {children}

      <ChatBubble
        isOpen={openModal}
        disableVideo={disableVideo}
        setIsOpen={setOpenModal}
        loadBambuser={handleLoadBambuser}
        sendTagManager={sendTagManager}
      />

      {loadBambuser && (
        <>
          <Script
            async
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=a1527730-a5c4-4a60-a6c9-e36d362178ed"
            onLoad={onLoadZendesk}
          />

          {!disableVideo && (
            <Script
              id="bambuser-one-to-one"
              async
              src="https://one-to-one.bambuser.com/embed.js"
            />
          )}
        </>
      )}
    </BambuserContext.Provider>
  )
}

export interface BambuserContextProps {
  oneToOneEmbed: OneToOneEmbed
  updateOneToOne: () => void
  cartIntegration: (oneToOneEmbed: OneToOneEmbed) => void
  provideProductData: (oneToOneEmbed: OneToOneEmbed) => void
  handleLoadBambuser: () => void
  handleOpenVideoCall: () => void
}

export function useBambuser() {
  return useContext(BambuserContext) as BambuserContextProps
}
