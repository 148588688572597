import {useCallback} from 'react'
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'

import {Button} from '@festi/common/components/buttons'
import {
  Modal,
  ModalSize,
  ModalContentNarrow,
} from '@festi/common/components/common'
import {H4, P} from '@festi/common/components/typography'
import VideoCall from '@festi/common/components/videocall/VideoCall'

const ImageCover = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;

  ${media.sm`
    height: 300px;
  `}

  ${media.xl`
    height: 350px;
    object-fit: top;
  `}
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 24px;
  height: 100%;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
`

interface Props {
  isOpen: boolean
  disableVideo?: boolean
  onRequestClose: () => void
  sendTagManager: (event: string, label: string) => void
}

export default function BambuserModal({
  isOpen,
  disableVideo,
  onRequestClose,
  sendTagManager,
}: Props) {
  const onClickChat = useCallback(() => {
    onRequestClose()
    sendTagManager('open chat', 'chat')
    window.zE.activate()
  }, [onRequestClose, sendTagManager])

  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.Small}
      darkBackground
      onRequestClose={onRequestClose}
    >
      <ImageCover src="/images/bambuser-staff.webp" alt="Staff" />

      <ModalContentNarrow>
        <H4 withGutter>Hvernig getum við aðstoðað?</H4>

        <ContentWrapper>
          {!disableVideo && (
            <Item>
              <P>
                Myndsímtal við sölufulltrúa í verslun. Þú sérð okkur, en ræður
                hvort við sjáum þig. Opnunartími 11-19 alla virka daga.
              </P>

              <VideoCall
                onClick={() => {
                  onRequestClose()
                  sendTagManager('open videochat', 'videochat')
                }}
              />
            </Item>
          )}

          <Item>
            <P>Netspjall við starfsmann í þjónustuveri.</P>
            <Button
              accentColor="green"
              icon={Chat3LineIcon}
              onClick={onClickChat}
              withBar
            >
              Netspjall
            </Button>
          </Item>
        </ContentWrapper>
      </ModalContentNarrow>
    </Modal>
  )
}
