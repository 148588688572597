import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {omit} from 'lodash'
import {v4 as uuidv4} from 'uuid'
import {useCookies} from 'react-cookie'
import {useEffectOnce, useLocalStorage} from 'react-use'
import {default as searchInsights, InsightsMethodMap} from 'search-insights'

import settings from '../constants/settings'

export interface EventContextProps {
  listById: ListByIdProps
  userToken?: string
  algoliaInit: boolean
  handleListNameState: (id: string, list: string) => void
  setCurrentIndex: (index: string) => void
  setCurrentQueryID: (id: string) => void
  sendClickEvent: (
    event: keyof InsightsMethodMap,
    name: string,
    id: string,
    position?: number,
  ) => void
  sendAddToCartEvent: (
    event: keyof InsightsMethodMap,
    name: string,
    id: string,
  ) => void
  sendFilterClickedEvent: (
    event: keyof InsightsMethodMap,
    name: string,
    filter: string,
  ) => void
  sendViewEvent: (
    event: keyof InsightsMethodMap,
    name: string,
    id: string,
  ) => void
}

interface EventProviderProps {
  children: React.ReactNode
}
interface ListByIdProps {
  [key: string]: string
}

export const EventContext = createContext<Partial<EventContextProps>>({})

export function EventProvider({children}: EventProviderProps) {
  const [algoliaInit, setAlgoliaInit] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useLocalStorage<string>(
    'algoliaIndex',
    '',
  )
  const [currentQueryID, setCurrentQueryID] = useLocalStorage<string>(
    'algoliaQueryID',
    '',
  )

  const [listById, setListById] = useLocalStorage<ListByIdProps>(
    'local-list-name',
    {},
  )
  const [eventsToken] = useLocalStorage<string>('eventsToken', uuidv4())

  const [cookies] = useCookies(['cookiehub', '_ga'])

  const extractGoogleAnalyticsUserIdFromCookie = (): string | undefined => {
    const gaCookie = cookies?._ga

    if (gaCookie) {
      // Remove the Google Analytics tracker from the device ID.
      const userIdParts = gaCookie.split('.').slice(-2)
      if (userIdParts.length === 2) {
        return userIdParts.join('_')
      }
      return undefined
    }
    return undefined
  }

  const userToken = extractGoogleAnalyticsUserIdFromCookie() || eventsToken

  const sendClickEvent = useCallback(
    (
      event: keyof InsightsMethodMap,
      name: string,
      id: string,
      position?: number,
    ) => {
      if (algoliaInit && currentQueryID && currentIndex) {
        searchInsights(event, {
          eventName: name,
          index: currentIndex,
          queryID: currentQueryID,
          objectIDs: [id],
          positions: [position],
        })
      }
    },
    [algoliaInit, currentQueryID, currentIndex],
  )

  const sendAddToCartEvent = useCallback(
    (event: keyof InsightsMethodMap, name: string, id: string) => {
      if (algoliaInit && currentQueryID && currentIndex) {
        searchInsights(event, {
          eventName: name,
          index: currentIndex,
          queryID: currentQueryID,
          objectIDs: [id],
        })
      }
    },
    [algoliaInit, currentQueryID, currentIndex],
  )

  const sendFilterClickedEvent = useCallback(
    (event: keyof InsightsMethodMap, name: string, filter: string) => {
      if (algoliaInit) {
        searchInsights(event, {
          eventName: name,
          index: currentIndex,
          queryID: currentQueryID,
          filters: [filter],
        })
      }
    },
    [algoliaInit, currentIndex, currentQueryID],
  )

  const sendViewEvent = useCallback(
    (event: keyof InsightsMethodMap, name: string, id: string) => {
      if (algoliaInit && currentQueryID && currentIndex) {
        searchInsights(event, {
          eventName: name,
          index: currentIndex,
          queryID: currentQueryID,
          objectIDs: [id],
        })
      }
    },
    [algoliaInit, currentQueryID, currentIndex],
  )

  const handleListNameState = useCallback(
    (id: string, list: string) => {
      const oldKeys = Object.keys(listById || {})
      const oldList =
        oldKeys.length >= 10 ? omit(listById, oldKeys[0]) : listById
      const newList = {...oldList, [id]: list}

      setListById(newList)
    },
    [listById, setListById],
  )

  useEffect(() => {
    userToken && searchInsights('setUserToken', userToken)
  }, [userToken])

  useEffectOnce(() => {
    setAlgoliaInit(true)
    searchInsights('init', {
      appId: settings.algoliaApplicationId,
      apiKey: settings.algoliaApiKey,
    })
  })

  return (
    <EventContext.Provider
      value={{
        listById,
        userToken,
        algoliaInit,
        handleListNameState,
        setCurrentQueryID,
        setCurrentIndex,
        sendClickEvent,
        sendAddToCartEvent,
        sendFilterClickedEvent,
        sendViewEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  )
}

export function useEvents() {
  return useContext(EventContext) as EventContextProps
}
