import React from 'react'

import ReactModal from 'react-modal'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

interface Props extends ReactModal.Props {
  backgroundColor?: string
  children?: React.ReactNode
}

interface CustomModalStyleProps {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

const StyledModal = styled(ReactModal)<Props>`
  background-color: ${(p) => palette(p?.backgroundColor || 'white')};
`

const customStyles: CustomModalStyleProps = {
  overlay: {
    zIndex: styledTheme.zIndex.modal,
  },
  content: {
    height: '100vh',
    WebkitOverflowScrolling: 'touch',
  },
}

const CloseButton = styled.button`
  background: none;
  border-radius: 0;
  border: 0;
  position: absolute;
  ${fluidRange('top', '16px', '24px')}
  ${fluidRange('right', '16px', '24px')}
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export default function FullScreenModal({
  backgroundColor,
  children,
  onRequestClose,
  ...props
}: Props): JSX.Element {
  return (
    <StyledModal
      style={customStyles}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      ariaHideApp={false}
      backgroundColor={backgroundColor}
      {...props}
    >
      {children}
      {!!onRequestClose && (
        <CloseButton onClick={onRequestClose}>
          <CloseLineIcon size={28} color={styledTheme.palette.blue} />
        </CloseButton>
      )}
    </StyledModal>
  )
}
