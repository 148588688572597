import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {BambuserModal} from '@festi/common/components/videocall'
import {useComparison} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

const BubbleWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: ${styledTheme.zIndex.chatBubble};
  transition: bottom 0.2s ease-out;

  &.isComparing {
    bottom: 136px;
  }
`

const ImageIcon = styled.div`
  ${fluidRange('width', '60px', '112px', 'xs', 'lg')}
  ${fluidRange('height', '60px', '112px', 'xs', 'lg')}
  background-image: url('/images/bambuser-service.webp');
  background-size: 200%;
  background-position: 40% top;
  background-repeat: no-repeat;
  border: 2px solid ${palette('green')};
  border-radius: 50%;
  cursor: pointer;
`

interface Props {
  isOpen: boolean
  disableVideo?: boolean
  setIsOpen: (open: boolean) => void
  loadBambuser: () => void
  sendTagManager: (event: string, label: string) => void
}

export default function ChatBubble({
  isOpen,
  disableVideo,
  setIsOpen,
  loadBambuser,
  sendTagManager,
}: Props) {
  const {isComparing} = useComparison()

  return (
    <>
      <BubbleWrapper className={cn({isComparing})}>
        <ImageIcon
          onClick={() => {
            setIsOpen(!isOpen)
            loadBambuser()
            sendTagManager(isOpen ? 'close main' : 'open main', 'main bubble')
          }}
          className={cn({isOpen})}
        />
      </BubbleWrapper>

      <BambuserModal
        isOpen={isOpen}
        disableVideo={disableVideo}
        sendTagManager={sendTagManager}
        onRequestClose={() => setIsOpen(false)}
      />
    </>
  )
}
