import React from 'react'

import ReactModal from 'react-modal'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette, ifProp, switchProp} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'

export enum ModalSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}
interface CustomModalStyleProps {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

const StyledModal = styled(ReactModal)<Props>`
  background-color: ${(p) => palette(p?.backgroundColor || 'white')};
  overflow: unset;
  outline: none;
  max-width: ${switchProp('size', {
    xs: '500px',
    sm: '800px',
    md: '1024px',
    lg: '1560px',
  })};
  ${ifProp(
    {bottomMobile: true},
    css`
      position: absolute;
      bottom: 0;
      width: 100%;
    `,
    css`
      position: relative;
      top: 32px;
      width: calc(100% - 32px);
    `,
  )}
  z-index: 999999;

  ${media.sm`
    position: relative;
    bottom: unset;
    ${fluidRange('top', '32px', '128px')}
    min-height: unset;
    width: calc(100% - 32px);
    margin-bottom: 118px;
  `}
`

const customStyles: CustomModalStyleProps = {
  overlay: {
    position: 'fixed',
    overflowY: 'scroll',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: styledTheme.palette.ui60,
    zIndex: styledTheme.zIndex.modal,
  },
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

export const ModalContentNarrow = styled.div`
  ${fluidRange('padding-top', '32px', '64px')}
  ${fluidRange('padding-bottom', '24px', '80px')}
  ${fluidRange('padding-left', '32px', '120px')}
  ${fluidRange('padding-right', '32px', '120px')}
`

export const ModalContentWide = styled.div`
  ${fluidRange('padding-top', '32px', '64px')}
  ${fluidRange('padding-bottom', '32px', '56px')}
  ${fluidRange('padding-left', '32px', '56px')}
  ${fluidRange('padding-right', '32px', '56px')}
`

interface CloseButtonProps {
  darkBackground?: boolean
}

const CloseButton = styled.button<CloseButtonProps>`
  position: absolute;
  ${fluidRange('top', '16px', '24px')}
  ${fluidRange('right', '16px', '24px')}
  padding: 0;
  background: none;
  background-color: ${(p) => (p.darkBackground ? 'white' : 'transparent')};
  border: 0;
  border-radius: 50%;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

interface Props extends ReactModal.Props {
  size?: ModalSize
  roundBorder?: boolean
  bottomMobile?: boolean
  darkBackground?: boolean
  backgroundColor?: string
  children?: React.ReactNode
}

export default function Modal({
  size = ModalSize.Small,
  roundBorder,
  bottomMobile,
  darkBackground,
  backgroundColor,
  children,
  onRequestClose,
  ...props
}: Props): JSX.Element {
  return (
    <StyledModal
      style={{
        ...customStyles,
        ...{content: {borderRadius: roundBorder ? 20 : 0}},
      }}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      ariaHideApp={false}
      backgroundColor={backgroundColor}
      bottomMobile={bottomMobile}
      size={size}
      {...props}
    >
      {children}
      {!!onRequestClose && (
        <CloseButton darkBackground={darkBackground} onClick={onRequestClose}>
          <CloseLineIcon size={28} color={styledTheme.palette.blue} />
        </CloseButton>
      )}
    </StyledModal>
  )
}
