import {styledTheme} from '@festi/common/themes'
import classNames from 'classnames'
import {useState} from 'react'
import styled from 'styled-components'

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  border-bottom: 1px solid ${styledTheme.palette.ui10Solid};
  margin-bottom: 24px;

  &.small {
    gap: 20px;
  }
`
const Tab = styled.button`
  position: relative;
  width: fit-content;
  padding-block: 24px;
  padding-inline: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${styledTheme.palette.ui40Solid};
  background: ${styledTheme.palette.transparent};
  border: 0;
  border-radius: 0;
  cursor: ${styledTheme.cursor.pointer};

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 3px;
    background-color: ${styledTheme.palette.link};
    right: 0;
    left: 0;
    bottom: -1px;
    opacity: 0;
    transition: opacity 200ms;
  }

  &.isActive {
    color: ${styledTheme.palette.link};
    cursor: default;

    &:after {
      opacity: 1;
    }
  }
  &.small {
    padding-block: 16px;
    font-size: 16px;
  }
`
const TabContent = styled.div`
  display: none;

  &.isActive {
    display: block;
  }
`

interface TabsProps {
  tabs: {label: string; content: React.ReactNode}[]
  small?: boolean
}

export default function Tabs({tabs, small = false}: TabsProps) {
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <>
      <TabsWrapper className={classNames({small})}>
        {tabs.map(({label}, i) => (
          <Tab
            key={i}
            className={classNames({isActive: activeTab === i, small})}
            onClick={() => setActiveTab(i)}
          >
            {label}
          </Tab>
        ))}
      </TabsWrapper>
      {tabs.map(({content}, i) => (
        <TabContent key={i} className={classNames({isActive: activeTab === i})}>
          {content}
        </TabContent>
      ))}
    </>
  )
}
