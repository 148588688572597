import {
  VariantList,
  VariantDetail,
  VariantPriceHistory,
} from '../api/rest'
import settings from '../constants/settings'

export function getListings(productVariant: VariantDetail | VariantList) {
  return productVariant?.listings?.[settings.channel]
}

export function getListingPrice(productVariant?: VariantDetail | VariantList) {
  return productVariant?.listings?.[settings.channel]?.price
}

export function getPriceHistory(
  productVariant: VariantDetail,
): VariantPriceHistory[] {
  return productVariant?.listings?.[settings.channel]?.priceHistory
}
