import getConfig from 'next/config'

const config = getConfig()?.publicRuntimeConfig

const channel: 'webshop' | 'dutyfree' = config?.channel ?? 'webshop'
const channelPricePath = `listings.${channel}.price.lowestPrice`
const channelListingPath = `listings.${channel}.visible`
const baseUrl = config?.baseUrl ?? ''
const saleorBaseUrl = config?.saleorBaseUrl ?? ''
const dutyfreeUrl = config?.dutyfreeUrl ?? ''
const algoliaApplicationId = config?.algoliaApplicationId ?? ''
const algoliaApiKey = config?.algoliaApiKey ?? ''
const algoliaEnvPrefix = config?.algoliaEnvPrefix ?? ''
const algoliaEnv = config?.algoliaEnv ?? ''
const bambuserId = config?.bambuserId ?? ''
const bambuserQueue = config?.bambuserQueue ?? 'pogiCmYkgnEzZz7XTPP5'

// Feature flags
const ffPageNav = true

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  channel,
  channelPricePath,
  channelListingPath,
  baseUrl,
  saleorBaseUrl,
  dutyfreeUrl,
  algoliaApplicationId,
  algoliaApiKey,
  algoliaEnvPrefix,
  algoliaEnv,
  bambuserId,
  bambuserQueue,
  ffPageNav,
}
